<template>
    <v-container>
        <v-row justify="center">
            <!-- <v-col :xs="12" :sm="12" :md="5" :lg="5" :xl="5" :cols="12">
                <v-card height="270" to="/sugestoes"  color="#f1aa48">
                    <v-card-title class="white--text">
                        <v-row align="end" class="text-right">
                            <v-col class="ma-8 display-1">
                                Sugestões
                            </v-col>
                            <v-img width="80" src="../../assets/INFORM E TRANSFORME5.png" alt="" class="shrink pa-0 mr-6" />
                        </v-row>
                    </v-card-title>
                    <v-card-text class="white--text">
                        <v-row class="text-right">
                            <v-col class="mr-8 ml-8 body-2">
                                Registro de ideias com o propósito de incentivar a criação de soluções que podem
                                trazer para o HRO a melhoria em seus processos e serviços.
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>

            </v-col> -->
            <v-col :xs="12" :sm="12" :md="5" :lg="5" :xl="5" :cols="12">
                <v-card height="270" to='/Ouvidoria' color="#447a9c" >
                    
                    <v-card-title class="white--text">
                        <v-row dense class="ma-auto pt-6">
                            <v-col cols="4"></v-col>
                            <v-col dense col="1" class="ma-0 pa-0 text-right">
                               <v-icon size="40" color="white">mdi-account</v-icon>
                            </v-col>
                            <v-col dense cols="1" class="text-left">
                               <v-icon color="white">mdi-bullhorn-variant-outline</v-icon>
                            </v-col>
                            <v-col dense cols="5" class="ma-0 display-1 text-left">
                                Ouvidoria
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text class="white--text">
                        <v-row class="text-right">
                            <v-col class="mr-8 ml-8 body-2">
                                Serviço ofertado aos colaboradores que desejam registrar, elogios ou reclamações,
                                mediante algum fato.
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default {
    name: 'Informe',
    methods: {
       
    },
    mounted() {
    }
}
</script>